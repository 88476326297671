.pageWrapper {
  width: 100%;
  height: auto;
  position: relative;
}

.pageWrapper_auth {
  height: 100vh;
  overflow: hidden;
}

.mainContainer {
  width: 100%;
  max-width: 1260px;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 30px;
  // overflow: hidden;

  &__wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 980px) {
      flex-direction: column-reverse;
    }
  }
  &__sidebar {
    position: sticky;
    top: 0;
    height: 100%;
    padding-right: 30px;
    flex-grow: 1;
    @media (max-width: 1024px) {
      padding-right: 20px;
    }
    @media (max-width: 980px) {
      padding-right: 0;
      height: auto;
      position: static;
    }
  }
  &__content {
    flex-grow: 1;
    width: 100%;
    max-width: calc(100% - 260px);
    margin: 0 auto;
    @media (max-width: 1024px) {
      max-width: calc(100% - 220px);
    }
    @media (max-width: 980px) {
      max-width: none;
      width: 100%;
      // margin-bottom: 30px;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 18px;
  }
}

@import '../../../styles/mixins.scss';

.sectionHeading {
  position: relative;
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 28px;
  font-family: 'Arial', sans-serif;

  &_plain {
    margin: 0;
    @include max-screen-530 {
      margin: 0;
    }
  }

  &_new {
    margin-top: 15px;
    @include max-screen-530 {
      margin-top: 0;
    }
  }
}

@import '../../../styles/mixins.scss';

/*Слайдер*/
.booksSlider {
  width: 100%;
  height: auto;
  @include max-screen-1260 {
    overflow: hidden;
  }
  &__wrap {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 50px;
    width: 940px;
    font-size: 16px;
    @include max-screen-530 {
      font-size: 14px;
      width: 600px;
      margin-bottom: 44px;
    }
  }
  &__name {
    margin: 15px 0 12px;
    word-break: break-word;
    transition: color 0.3s ease;
    @include max-screen-530 {
      margin-bottom: 10px;
    }
    &:hover {
      color: #ff8800;
    }
  }
  &__cover a {
    display: block;
    position: relative;
    height: 256px;
    width: 180px;
    background-color: #eeeeee;
    overflow: hidden;
    border-radius: 5px;
    &:before {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80px;
      height: 100px;
      background: url('/icons/book-no-cover.svg') no-repeat 50%;
      background-size: cover;
      z-index: 0;
    }
  }
  &__cover img {
    position: relative;
    background-color: #eeeeee;
    color: transparent;
  }
  &__author a {
    color: #575757;
  }
}
/*Фикс нативных стилей слайдера в файле global.scss*/

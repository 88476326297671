@import '../../../styles/mixins.scss';

.header {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  margin: 37px 0 0;

  @include max-screen-530 {
    flex-direction: column;
    margin: 16px 0 0;
  }

  &_bottom {
    margin: 0 0 5px;
    align-items: center;
    @include max-screen-768 {
      flex-direction: column;
      margin: 0 0 5px;
      align-items: flex-start;
    }
    @include max-screen-530 {
      flex-direction: column;
    }

    .header__auth {
      @include max-screen-530 {
        position: static;
        order: 1;
        right: initial;
        top: initial;
      }
    }

    .header__radios {
      position: absolute;
      right: 0;
      bottom: -24px;
      @include max-screen-530 {
        position: static;
        right: initial;
        bottom: initial;
      }
    }

    .header__main {
      @include max-screen-768 {
        margin-bottom: 12px;
      }
      @include max-screen-530 {
        margin-bottom: 0;
      }
    }
  }
  &__logo {
    position: relative;
    top: -22px;
    padding-right: 15px;
    padding-left: 16px;
    @include max-screen-1060 {
      top: -16px;
      padding-left: 0;
    }
    @include max-screen-530 {
      top: -10px;
      padding-left: 0;
    }
  }
  &__logoLink {
    position: relative;
    width: 209px;
    height: 122px;
    display: block;
    @include max-screen-1060 {
      width: 178px;
      height: 105px;
    }
    @include max-screen-530 {
      width: 97px;
      height: 57px;
      padding-right: 0;
      margin-bottom: 3px;
    }
  }
  &__logoImg {
    width: 100%;
    height: auto;
    display: block;
  }
  &__main {
    box-sizing: border-box;
    width: 100%;
    max-width: 940px;
    margin-bottom: 22px;
    @include max-screen-530 {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &__menu {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding-right: 80px;
    @include max-screen-530 {
      padding-right: 0;
    }
  }
  &__menuItem {
    margin: 0 35px 20px 0;
    @include max-screen-1060 {
      margin: 0 18px 14px 0;
    }
    @include max-screen-530 {
      margin: 0 16px 14px 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__menuLink {
    position: relative;
    color: #575757;
    transition: none;
    &:hover {
      color: #000;
    }
    &_active {
      color: #000;
      cursor: default;
      &:hover {
        color: #000;
      }
    }
    &_new {
      color: #343434;
      &::before {
        content: '';
        position: absolute;
        right: -14px;
        top: -11px;
        width: 25px;
        height: 14.2px;
        background-image: url('/icons/new-icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  &__search {
    position: relative;
    @include max-screen-530 {
      margin-bottom: 20px;
    }

    .input {
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #ccc;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 10px 14px 10px 10px;
      font-size: 16px;
      font-family: 'Arial', sans-serif;
      &::placeholder {
        color: #ccc;
      }
    }
  }
  &__searchField {
    position: relative;
  }
  &__searchBtn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: url('/icons/lens.svg') no-repeat 0 0;
    background-size: cover;
    border: none;
    color: transparent;
    cursor: pointer;
  }
  &__searchForm {
    display: flex;
    flex-direction: column-reverse;
    @include max-screen-530 {
      flex-direction: column;
    }
  }
  &__radios {
    display: none;
    justify-content: flex-end;
    flex-direction: row;
    margin: 8px 0 0;
    font-size: 13px;
    @include max-screen-530 {
      margin: -9px 0 7px;
      display: flex;
    }

    &_active {
      display: flex;
    }
  }
  &__radiosTitle {
    color: #a6a6a6;
    margin-right: 10px;
  }
  &__searchLabel {
    color: #a6a6a6;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #575757;
    }
  }
  &__searchInput {
    appearance: none;
    height: 12px;
    width: 12px;
    padding: 1px;
    border: 1px solid #bcbcbc;
    border-radius: 1px;
    position: relative;
    bottom: -1.2px;
    margin-right: 4px;
    &:checked {
      background-color: #f09101;
      background-clip: content-box;
      color: #575757;
    }
    &:checked + .header__searchTxt {
      color: #575757;
    }
  }
  &__auth {
    position: absolute;
    right: 14px;
    top: 1px;
    cursor: pointer;
    padding-right: 15px;
  }
  &__authText {
    position: relative;
    color: #f09101;
    transition: color 0.3s ease;
    @include max-screen-530 {
      font-size: 14px;
    }

    &:hover {
      color: #ff8800;
    }
    &:after {
      content: '';
      position: absolute;
      right: -30px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url('/icons/user.svg') no-repeat 0 0;
      background-size: cover;
    }
  }
  &__copyright {
    position: relative;
    font-size: 14px;
    color: #636363;
    bottom: -7px;
    padding-right: 10px;
    margin: 0 auto;
    @include max-screen-1060 {
      margin: auto;
    }
    @include max-screen-768 {
      bottom: 0;
      order: 1;
      display: block;
      width: 100%;
    }
    @include max-screen-530 {
      order: 3;
    }
  }
  &__mail {
    font-size: 14px;
    color: #636363;
    position: absolute;
    bottom: -25px;
    right: 0;
    max-width: 940px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include max-screen-1060 {
      max-width: none;
      text-align: right;
    }
    @include max-screen-768 {
      padding: 20px 0 6px;
      position: static;
      text-align: left;
    }
    @include max-screen-530 {
      order: 2;
    }
    &_link {
      color: #636363;
      text-decoration: underline;
      &:hover {
        color: #636363;
        text-decoration: none;
      }
    }
  }
  &__bottomMenu {
    display: flex;
    width: 100%;
    max-width: 298px;
    justify-content: space-between;
    margin: 0 10px 15px 0;
  }
  &__mailTxt {
    margin-bottom: 15px;
    @include max-screen-530 {
      margin-bottom: 8px;
    }
    &_desktop {
      @include max-screen-530 {
        display: none;
      }
    }
    &_mob {
      display: none;
      @include max-screen-530 {
        display: inline-block;
      }
    }
  }
  &__bottomMenuItem {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.inputWrap {
  padding: 0 0 20px 0;
}

.input {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 14px 10px 10px;
  font-size: 16px;
  width: 100%;
  outline-color: #f19001;
  &::placeholder {
    color: #000;
    opacity: 0.3;
  }
}

.inputLabel {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  display: inline-block;
  &__star {
    color: #ff0000;
  }
}

.inputError {
  font-size: 12px;
  margin-top: 3px;
  color: #ff0000;
}

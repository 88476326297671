.inputBtn {
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  color: #000;
  font-weight: normal;
  padding: 9px 20px 11px;
  background: linear-gradient(180deg, #f7e076 0%, #f19001 100%);
  border-radius: 5px;
  overflow: hidden;
  border: none;
  cursor: pointer;
  &_disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

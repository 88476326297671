@import '../../../styles/mixins.scss';

.authPopup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: none;
  opacity: 0;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  &_active {
    display: flex;
    opacity: 1;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
  }

  &__wrap {
    position: relative;
    background: white;
    border-radius: 5px;
    max-width: 382px;
    width: 100%;
    padding: 30px;
    z-index: 1;
    @include max-screen-530 {
      padding: 20px;
      max-width: 343px;
      width: calc(100% - 20px);
    }
  }

  &__btn {
    position: absolute;
    width: 15px;
    height: 15px;
    background-image: url('/icons/close-gray-cross-btn.svg');
    background-repeat: no-repeat;
    background-size: cover;
    right: 8px;
    top: 8px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 1;
    }
    @include max-screen-530 {
      width: 13px;
      height: 13px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    @include max-screen-530 {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  &__controls {
    @include max-screen-530 {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
    }
  }

  &__controlsBtns {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    @include max-screen-530 {
      margin-top: 0;
      display: inline-flex;
      flex-direction: column;
    }
  }

  &__controlsBtn {
    position: relative;
    color: #575757;
    font-size: 14px;
    line-height: 24px;
    transition: color 0.3s ease;
    padding-left: 18px;
    @include max-screen-530 {
      padding-left: 15px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 3px;
      width: calc(100% - 19px);
      height: 1px;
      background-color: #575757;
      transition: opacity 0.3s ease;
      @include max-screen-530 {
        width: calc(100% - 16px);
      }
    }
    &_register {
      @include max-screen-530 {
        margin-bottom: 4px;
      }
      &:before {
        width: 14px;
        height: 13px;
        background-image: url('/icons/register.svg');
        @include max-screen-530 {
          width: 11px;
          height: 10px;
        }
      }
      &:after {
        @include max-screen-530 {
          width: calc(100% - 23px);
          right: 7px;
        }
      }
    }
    &_password {
      padding-left: 17px;
      &:before {
        background-image: url('/icons/password.svg');
        width: 12px;
        height: 15px;
        @include max-screen-530 {
          left: 1px;
          width: 10.1px;
          height: 13px;
        }
      }
      &:after {
        width: calc(100% - 18px);
      }
    }
    &:hover {
      color: #000;
      &:after {
        opacity: 0;
      }
    }
    @include max-screen-530 {
      font-size: 13px;
    }
  }
}

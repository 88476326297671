@import '../../../styles/mixins.scss';

.sidebar {
  height: fit-content;
  border-radius: 3px;
  background: #f9f9f9;
  padding: 20px 20px 12px 20px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.24);
  @include max-screen-980 {
    padding: 10px 0;
  }
  &_active {
    height: 74vh;
    overflow-y: scroll;
    @include max-screen-980 {
      height: auto;
      overflow: auto;
    }
  }
  &__title {
    display: none;
    @include max-screen-980 {
      display: block;
    }
  }
}

//Пункты меню
.sidebarList {
  position: relative;
  &__item {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #575757;
    font-size: 14px;
    @include max-screen-980 {
      padding: 0 17px 10px 17px;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: #e5e5e5;
    }
    &:after {
      background: transparent;
      bottom: -2px;
    }
    &::first-letter {
      text-transform: uppercase;
    }
    &:last-child {
      margin-bottom: 0;
      @include max-screen-980 {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      &:before,
      &:after {
        display: none;
      }
    }
    a {
      margin-bottom: 20px;
      color: #575757;
      transition: color 0.3s ease;
      text-decoration: none;
    }
    &_title {
      color: #575757;
      font-weight: bold;
      padding-left: 15px;
      font-size: 14px;
      color: #777575;
      @include max-screen-980 {
        display: none;
      }
      span {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -15px;
          top: 50%;
          opacity: 0.8;
          transform: translateY(-50%);
          width: 10px;
          height: 14px;
          background: url('/icons/book-icon.svg') no-repeat 0 0;
          background-size: cover;
        }
      }
    }
    &_toggle {
      cursor: pointer;
      font-size: 14px;
      color: #f09101;
      position: relative;
      margin-bottom: 0;
      padding-bottom: 0;
      &::before {
        display: none;
      }
      &:after {
        content: '';
        position: absolute;
        left: 80px;
        top: 42%;
        border: 4px solid transparent;
        border-top: 4px solid #f09101;
        width: fit-content;
        @include max-screen-980 {
          top: 38%;
          left: 96px;
        }
      }
      .active:after {
        top: 15%;
        border: 4px solid transparent;
        border-bottom: 4px solid #5976db;
      }
    }
    &_toggleSubGenres {
      &:after {
        left: 102px;
        @include max-screen-980 {
          left: 120px;
        }
      }
    }
  }
  &__hiddenGenres {
    display: none;
  }
}

.sidebarList__item_toggle.active {
  display: none;
  ~ .sidebarList__hiddenGenres {
    display: block;
  }
}
